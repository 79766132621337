@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@500;700&display=swap');

*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

@media (max-width: 600px) {
  html {
    font-size: 50%;
  }
}
